import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "promo-banner" };
const _hoisted_2 = ["href", "target"];
const _hoisted_3 = ["innerHTML"];
const _hoisted_4 = ["innerHTML"];
export default /*@__PURE__*/ _defineComponent({
    __name: 'PromoBanner',
    props: {
        content: {
            type: String,
            required: true,
        },
        link: {
            type: String,
            required: false,
            default: undefined,
        },
        target: {
            type: String,
            required: false,
            default: '_self',
        }
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (__props.link)
                    ? (_openBlock(), _createElementBlock("a", {
                        key: 0,
                        href: __props.link,
                        target: __props.target,
                        rel: "noopener noreferrer"
                    }, [
                        _createElementVNode("div", {
                            class: "promo-banner__content",
                            innerHTML: __props.content
                        }, null, 8, _hoisted_3)
                    ], 8, _hoisted_2))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "promo-banner__content",
                        innerHTML: __props.content
                    }, null, 8, _hoisted_4))
            ]));
        };
    }
});
