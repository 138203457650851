import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "section-faqs" };
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "title z-h3" };
const _hoisted_4 = { class: "faqs" };
const _hoisted_5 = ["onClick"];
const _hoisted_6 = { class: "z-h5" };
const _hoisted_7 = ["onClick"];
const _hoisted_8 = {
    key: 0,
    width: "20",
    height: "20",
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
};
const _hoisted_9 = {
    key: 1,
    width: "20",
    height: "20",
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
};
const _hoisted_10 = ["innerHTML"];
import { ref } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FAQs',
    props: {
        header: {
            type: Object,
            required: true
        },
        faqs: {
            type: (Array),
            required: true
        }
    },
    setup(__props) {
        const openedItems = ref([]);
        const toggleItem = (index) => {
            if (openedItems.value.includes(index)) {
                openedItems.value = openedItems.value.filter((i) => i !== index);
            }
            else {
                openedItems.value = [...openedItems.value, index];
            }
        };
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("section", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("h3", _hoisted_3, [
                        _createTextVNode(_toDisplayString(props.header.line1) + " ", 1),
                        _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                        _createTextVNode(" " + _toDisplayString(props.header.line2), 1)
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.faqs, (faq, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: "faq"
                            }, [
                                _createElementVNode("div", {
                                    class: "question",
                                    onClick: ($event) => (toggleItem(index))
                                }, [
                                    _createElementVNode("h5", _hoisted_6, _toDisplayString(faq.question), 1),
                                    _createElementVNode("button", {
                                        onClick: (event) => { toggleItem(index); event.stopPropagation(); }
                                    }, [
                                        (openedItems.value.includes(index))
                                            ? (_openBlock(), _createElementBlock("svg", _hoisted_8, _cache[1] || (_cache[1] = [
                                                _createElementVNode("path", {
                                                    d: "M15 13L10 8L5 13",
                                                    stroke: "#737373",
                                                    "stroke-width": "1.67",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round"
                                                }, null, -1)
                                            ])))
                                            : (_openBlock(), _createElementBlock("svg", _hoisted_9, _cache[2] || (_cache[2] = [
                                                _createElementVNode("path", {
                                                    d: "M15 8L10 13L5 8",
                                                    stroke: "#737373",
                                                    "stroke-width": "1.67",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round"
                                                }, null, -1)
                                            ])))
                                    ], 8, _hoisted_7)
                                ], 8, _hoisted_5),
                                _withDirectives(_createElementVNode("div", {
                                    class: "answer",
                                    innerHTML: faq.answer
                                }, null, 8, _hoisted_10), [
                                    [_vShow, openedItems.value.includes(index)]
                                ])
                            ]));
                        }), 128))
                    ])
                ])
            ]));
        };
    }
});
