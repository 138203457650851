import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@images/AppSubscriptionLanding/TrustPilotReviews/5stars.png';
import _imports_1 from '@images/AppSubscriptionLanding/TrustPilotReviews/4stars.png';
const _hoisted_1 = { className: "trustpilot-reviews" };
const _hoisted_2 = { class: "reviews-container" };
const _hoisted_3 = { class: "title z-h1" };
const _hoisted_4 = { class: "show-for-medium-down" };
const _hoisted_5 = { class: "carousel-container" };
const _hoisted_6 = {
    id: "reviews-slider",
    class: "clearfix slides"
};
const _hoisted_7 = { class: "reviews-item" };
const _hoisted_8 = { class: "description z-h5" };
const _hoisted_9 = { class: "reviews-item" };
const _hoisted_10 = { class: "description z-h5" };
const _hoisted_11 = { class: "reviews-item" };
const _hoisted_12 = { class: "description z-h5" };
const _hoisted_13 = { class: "reviews show-for-large-up" };
const _hoisted_14 = { class: "review" };
const _hoisted_15 = { class: "description z-h5" };
const _hoisted_16 = { class: "review" };
const _hoisted_17 = { class: "description z-h5" };
const _hoisted_18 = { class: "review" };
const _hoisted_19 = { class: "description z-h5" };
import CarouselShared from "@components/Core/CarouselShared.vue";
export default /*@__PURE__*/ _defineComponent({
    __name: 'TrustPilotReviews',
    props: {
        t: {
            type: Object,
            default: () => ({
                sectionTitle: "Music to our ears",
                review1: "I'm down 12lbs already and have never felt so motivated to workout. Who knew fitness could be so fun! Thank you Zumba.",
                review2: "Not sure what I love more… How fun the workouts are or that so many of my friends are commenting that I look in such great shape.",
                review3: "You can incorporate it easily in your daily schedule. The workouts are motivating, plus the instructors are fun and have easy-to-follow choreo.",
            })
        },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("section", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("h1", _hoisted_3, _toDisplayString(props.t.sectionTitle), 1),
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                            _createVNode(CarouselShared, {
                                "slide-selector": ".reviews-item",
                                "slider-selector": "#reviews-slider",
                                "include-dot-buttons": true,
                                "include-direction-buttons": false,
                                interval: 7000,
                                "amount-of-slides": 3
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_6, [
                                        _createElementVNode("div", _hoisted_7, [
                                            _cache[0] || (_cache[0] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
                                            _createElementVNode("span", _hoisted_8, "“" + _toDisplayString(props.t.review1) + "”", 1)
                                        ]),
                                        _createElementVNode("div", _hoisted_9, [
                                            _cache[1] || (_cache[1] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
                                            _createElementVNode("span", _hoisted_10, "“" + _toDisplayString(props.t.review2) + "”", 1)
                                        ]),
                                        _createElementVNode("div", _hoisted_11, [
                                            _cache[2] || (_cache[2] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
                                            _createElementVNode("span", _hoisted_12, "\"" + _toDisplayString(props.t.review3) + "\"", 1)
                                        ])
                                    ])
                                ]),
                                _: 1
                            })
                        ])
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                            _cache[3] || (_cache[3] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
                            _createElementVNode("span", _hoisted_15, "“" + _toDisplayString(props.t.review1) + "”", 1)
                        ]),
                        _createElementVNode("div", _hoisted_16, [
                            _cache[4] || (_cache[4] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
                            _createElementVNode("span", _hoisted_17, "“" + _toDisplayString(props.t.review2) + "”", 1)
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                            _cache[5] || (_cache[5] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
                            _createElementVNode("span", _hoisted_19, "\"" + _toDisplayString(props.t.review3) + "\"", 1)
                        ])
                    ])
                ])
            ]));
        };
    }
});
