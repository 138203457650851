import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "f-inter instructions" };
const _hoisted_2 = { class: "steps" };
const _hoisted_3 = { class: "z-b4 step" };
const _hoisted_4 = { class: "z-b4 step" };
const _hoisted_5 = { class: "z-b4 step" };
const _hoisted_6 = ["href"];
const _hoisted_7 = { class: "z-b4 step" };
const _hoisted_8 = { class: "z-b4 step" };
const _hoisted_9 = { class: "z-b4 inter faq" };
const _hoisted_10 = { class: "z-b4 faq_text" };
const _hoisted_11 = ["href"];
const _hoisted_12 = { class: "buttons" };
import Modal from "@components/Core/Modal.vue";
import { validator } from '@ts/Util/i18n';
import { isMobile } from "@ts/Util/responsiveness";
import ZButton from "@components/Core/ZButton.vue";
import ExternalLinkSvg from '@icons/ic-external-link.svg';
const tDefaults = {
    headerFSA: 'FSA/HSA Post-Purchase Reimbursement Instructions',
    i1: 'Complete Your Purchase:',
    i1d: 'First, purchase your Zumba App subscription. We recommend choosing the annual plan for maximum savings and convenience, as it provides the best value and reduces the need for multiple submissions.',
    i2: 'Save Your Receipt:',
    i2d: 'Keep a copy of your purchase receipt as proof of payment.',
    i3: 'Complete TrueMed Questioner:',
    i3d: 'Obtain your Letter of Medical Necessity by filling out ',
    thisForm: 'this form',
    i4: 'Submit Documentation:',
    i4d: 'Upload your receipt for the Zumba App subscription and your TrueMed Letter of Medical Necessity (LMN).',
    i5: 'Complete the Reimbursement Form',
    moreQuestions: 'More questions?',
    visitFAQ: 'Visit our FAQ',
    close: 'Close',
};
export default /*@__PURE__*/ _defineComponent({
    __name: 'FsaHsaInformation',
    props: {
        t: {
            type: Object,
            default: () => tDefaults,
            validator: validator(tDefaults)
        },
        show: {
            type: Boolean,
            required: true,
        },
        trueMedLink: {
            type: String,
            required: true,
        },
        faqLink: {
            type: String,
            required: true,
        },
    },
    emits: ['closed'],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        return (_ctx, _cache) => {
            return (_openBlock(), _createBlock(Modal, {
                show: __props.show,
                "show-close-button": "",
                "mobile-drawer": "",
                "close-on-mask": "",
                onClosed: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('closed')))
            }, {
                default: _withCtx(() => [
                    _createElementVNode("h5", {
                        class: _normalizeClass([_unref(isMobile)() ? 'z-h5' : 'z-h4', 'header'])
                    }, _toDisplayString(__props.t.headerFSA), 3),
                    _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("ol", _hoisted_2, [
                            _createElementVNode("li", _hoisted_3, [
                                _createElementVNode("strong", null, _toDisplayString(__props.t.i1), 1),
                                _createTextVNode(" " + _toDisplayString(__props.t.i1d), 1)
                            ]),
                            _createElementVNode("li", _hoisted_4, [
                                _createElementVNode("strong", null, _toDisplayString(__props.t.i2), 1),
                                _createTextVNode(" " + _toDisplayString(__props.t.i2d), 1)
                            ]),
                            _createElementVNode("li", _hoisted_5, [
                                _createElementVNode("strong", null, _toDisplayString(__props.t.i3), 1),
                                _createTextVNode(" " + _toDisplayString(__props.t.i3d) + " ", 1),
                                _createElementVNode("a", {
                                    href: __props.trueMedLink,
                                    class: "link",
                                    target: "_blank"
                                }, [
                                    _createTextVNode(_toDisplayString(__props.t.thisForm) + " ", 1),
                                    _createVNode(_unref(ExternalLinkSvg))
                                ], 8, _hoisted_6),
                                _cache[2] || (_cache[2] = _createTextVNode(". "))
                            ]),
                            _createElementVNode("li", _hoisted_7, [
                                _createElementVNode("strong", null, _toDisplayString(__props.t.i4), 1),
                                _createTextVNode(" " + _toDisplayString(__props.t.i4d), 1)
                            ]),
                            _createElementVNode("li", _hoisted_8, [
                                _createElementVNode("strong", null, _toDisplayString(__props.t.i5), 1)
                            ])
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("strong", _hoisted_10, _toDisplayString(__props.t.moreQuestions), 1),
                            _createElementVNode("a", {
                                href: __props.faqLink,
                                class: "inter link",
                                target: "_blank"
                            }, [
                                _createTextVNode(_toDisplayString(__props.t.visitFAQ) + " ", 1),
                                _createVNode(_unref(ExternalLinkSvg))
                            ], 8, _hoisted_11)
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                            _createVNode(ZButton, {
                                variety: "optimus",
                                onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('closed')))
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(__props.t.close), 1)
                                ]),
                                _: 1
                            })
                        ])
                    ])
                ]),
                _: 1
            }, 8, ["show"]));
        };
    }
});
