import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '@images/AppSubscriptionLanding/JoinFree/qr.png';
const _hoisted_1 = { class: "section-join-free" };
const _hoisted_2 = { class: "container" };
const _hoisted_3 = { class: "header z-h3" };
const _hoisted_4 = { class: "line1" };
const _hoisted_5 = { class: "line2" };
const _hoisted_6 = { class: "qr" };
const _hoisted_7 = { class: "qr-text scan-text z-b2 f-inter" };
const _hoisted_8 = { class: "stores" };
const _hoisted_9 = ["href"];
const _hoisted_10 = ["href"];
import AppStore from "@images/AppSubscriptionLanding/JoinFree/AppStore.svg";
import PlayStore from "@images/AppSubscriptionLanding/JoinFree/PlayStore.svg";
export default /*@__PURE__*/ _defineComponent({
    __name: 'JoinFree',
    props: {
        t: {
            type: Object,
            default: () => ({})
        },
        smartLink: {
            type: String,
            default: ''
        }
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("section", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("h3", _hoisted_3, [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(props.t.header.line1), 1),
                        _createElementVNode("span", _hoisted_5, _toDisplayString(props.t.header.line2), 1)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                        _renderSlot(_ctx.$slots, "qrcode", {}, () => [
                            _cache[0] || (_cache[0] = _createElementVNode("img", {
                                src: _imports_0,
                                alt: "QR Code"
                            }, null, -1))
                        ])
                    ]),
                    _createElementVNode("p", _hoisted_7, _toDisplayString(props.t.scanText), 1),
                    _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("a", {
                            id: "ios_link",
                            href: props.smartLink ? props.smartLink : props.t.appStoreLink,
                            target: "_blank",
                            rel: "noopener noreferrer"
                        }, [
                            _createVNode(_unref(AppStore), { class: "store" })
                        ], 8, _hoisted_9),
                        _createElementVNode("a", {
                            id: "andrd_link",
                            href: props.smartLink ? props.smartLink : props.t.playStoreLink,
                            target: "_blank",
                            rel: "noopener noreferrer"
                        }, [
                            _createVNode(_unref(PlayStore), { class: "store" })
                        ], 8, _hoisted_10)
                    ])
                ])
            ]));
        };
    }
});
