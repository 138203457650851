import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "instructor-card-item" };
const _hoisted_2 = ["src", "alt"];
const _hoisted_3 = { class: "name z-h6" };
const _hoisted_4 = { class: "description z-b4 f-inter" };
export default /*@__PURE__*/ _defineComponent({
    __name: 'InstructorCard',
    props: {
        instructor: {
            type: Object,
            required: true
        },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("img", {
                    src: __props.instructor.imageUrl,
                    alt: __props.instructor.name,
                    class: "instructor-image"
                }, null, 8, _hoisted_2),
                _createElementVNode("h4", _hoisted_3, _toDisplayString(__props.instructor.name), 1),
                _createElementVNode("p", _hoisted_4, _toDisplayString(__props.instructor.description), 1)
            ]));
        };
    }
});
