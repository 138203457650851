import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "title z-h6" };
export default /*@__PURE__*/ _defineComponent({
    __name: 'FitnessTypeCard',
    props: {
        fitnessType: {
            type: Object,
            required: true
        },
    },
    setup(__props) {
        const props = __props;
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", {
                class: "fitness-type-item",
                style: _normalizeStyle({
                    'background-image': `url(${__props.fitnessType.backgroundUrl})`
                })
            }, [
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "overlay" }, null, -1)),
                _createElementVNode("p", _hoisted_1, _toDisplayString(__props.fitnessType.name), 1)
            ], 4));
        };
    }
});
